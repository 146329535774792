import { Button, Card, Col, Form, Grid, Input, Modal, Row, Select, Spin, Switch, notification } from 'antd';
import React, { useEffect, useState } from 'react';

import * as Api from 'api';
import ShareAccountItem from 'components/share/ShareAccountItem';
import useAuth from 'redux/auth/auth.hook';
import { CloseCircleOutlined } from '@ant-design/icons';
import classes from './style.module.scss';

const ManageSharePage = () => {
  const { auth: { profile } } = useAuth();
  const [cameras, setCameras] = useState<any[]>([]);
  const [shareAccounts, setShareAccounts] = useState<any[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (profile) {
      loadData();
    }
  }, [profile]);

  const loadData = () => {
    setLoading(true);
    Api.MAP_CAMERAS_PINS_DETAIL().then(((res: any) => {
      const result = JSON.parse(res.text);
      setCameras(result.cameras);
      setLoading(false);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
    getShareAccounts();
  };

  const clickEdit = (_account) => {
    setSelectedAccount(_account);
    form.setFieldsValue({
      camera_ids: _account.camera_ids,
      email: _account.email,
      able_delete: _account.able_delete == '1',
      able_category_change: _account.able_category_change == '1',
      able_request_hd: _account.able_request_hd == '1',
      able_tag: _account.able_tag == '1',
    });
  };

  const clickDelete = (_account) => {
    deleteShareAccount(_account);
  };

  const onShare = () => {
    form.validateFields().then(() => {
      const values = form.getFieldsValue();
      if (selectedAccount) {
        editShareAccount(values);
      } else {
        addShareAccount(values);
      }
    }).catch((reason) => {
      console.log('error', reason);
    });
  };

  const getShareAccounts = () => {
    Api.SHARE_USER_LIST().then(((res: any) => {
      const result = JSON.parse(res.text);
      setShareAccounts(result);
      setLoading(false);
      form.setFieldsValue({
        camera_ids: [],
        email: '',
        able_delete: true,
        able_category_change: true,
        able_request_hd: true,
        able_tag: true,
      });
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const addShareAccount = (values) => {
    setLoading(true);
    Api.SHRAE_USER_ADD(values).then(((res: any) => {
      const result = JSON.parse(res.text);
      console.log('result', result);
      if (result.result == 'ERROR') {
        setLoading(false);
        notification.open({
          message: result.message,
        });
      } else {
        getShareAccounts();
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const editShareAccount = (values) => {
    if (selectedAccount) {
      const param = { ...values };
      setLoading(true);
      Api.SHRAE_USER_EDIT(param).then(((res: any) => {
        const result = JSON.parse(res.text);
        if (result.result == 'ERROR') {
          setLoading(false);
          notification.open({
            message: result.message,
          });
        } else {
          setSelectedAccount(null);
          getShareAccounts();
        }
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  };

  const deleteShareAccount = (_account) => {
    setLoading(true);
    Api.SHRAE_USER_DELETE({ email: _account.email }).then(((res: any) => {
      const result = JSON.parse(res.text);
      if (result.result == 'ERROR') {
        setLoading(false);
        notification.open({
          message: result.message,
        });
      } else {
        getShareAccounts();
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onCancel = () => {
    setSelectedAccount(null);
    form.setFieldsValue({
      camera_ids: [],
      email: '',
      able_delete: true,
      able_category_change: true,
      able_request_hd: true,
      able_tag: true,
    });
  };

  return (
    <>
      <Spin spinning={loading} size="large">
        <Row>
          <Col md={12} xs={24} className={classes.sharedAccount}>
            <Card title="Cameras You Have Shared">
              {
                shareAccounts.map((account, i) => (
                  <ShareAccountItem key={i} account={account} cameras={cameras} clickEdit={(_account) => clickEdit(_account)} clickDelete={(_account) => clickDelete(_account)} />
                ))
              }
            </Card>
          </Col>
          <Col md={12} xs={24} className={classes.shareCamera}>
            <Card
              title="Add A New Shared Camera"
              key={selectedAccount ? 1 : 0}
              extra={
                selectedAccount &&
                (
                  <span onClick={onCancel} style={{ cursor: 'pointer' }}>
                    <CloseCircleOutlined />
                    &nbsp;Cancel
                  </span>
                )
              }
            >
              <Form
                form={form}
                initialValues={{
                  able_delete: true,
                  able_category_change: true,
                  able_request_hd: true,
                  able_tag: true,
                }}
              >
                <Form.Item
                  name="camera_ids"
                  label="Choose Cameras to Share:"
                  labelCol={{ span: 24 }}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    options={cameras.map((camera) => ({
                      label: camera.name,
                      value: camera.id,
                    }))}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Type Account to Share:"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid Email!',
                    },
                    {
                      required: true,
                      message: 'Please input recipient Email!',
                    },
                  ]}
                >
                  <Input placeholder="Input recipient's email" />
                </Form.Item>
                {/* <Divider /> */}
                <Form.Item name="able_delete" label="Can the recipient delete images?" colon={false} labelAlign="left" valuePropName="checked">
                  <Switch defaultChecked className={classes.optionSwitch} />
                </Form.Item>
                <Form.Item name="able_tag" label="Can the recipient tag images?" colon={false} labelAlign="left" valuePropName="checked">
                  <Switch defaultChecked className={classes.optionSwitch} />
                </Form.Item>
                <Form.Item name="able_request_hd" label="Can the recipient make XL and Video requests?" colon={false} labelAlign="left" valuePropName="checked">
                  <Switch defaultChecked className={classes.optionSwitch} />
                </Form.Item>
                <Form.Item name="able_category_change" label="Can the recipient change the category of an image?" colon={false} labelAlign="left" valuePropName="checked">
                  <Switch defaultChecked className={classes.optionSwitch} />
                </Form.Item>
                <Form.Item {...{ wrapperCol: { span: 8, offset: 8 } }} style={{ marginTop: 40 }}>
                  <Button type="primary" block onClick={onShare}>Share</Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default ManageSharePage;
