export const PATHS = {
  SIGNIN_WITH_TOKEN: '/signInToken/:token?',
  SIGNIN: '/signIn',
  AUTH_SIGNUP: '/authSignup',
  AUTH_DEVELOPER: '/authDeveloper',
  AUTH_SIGNUP_WELCOME: '/authSignupWelcome/:user_id',
  AUTH_SIGNUP_CAMERA: '/authSignupCamera/:user_id',
  AUTH_SUBSCRIPTION_STANDARD: '/authSubscriptionStandard/:user_id',
  AUTH_SUBSCRIPTION_PLUS: '/authSubscriptionPlus/:user_id',
  AUTH_RESET_PASSWORD: '/authResetPassword',
  AUTH_ENTER_PASSWORD: '/authEnterPassword',
  AUTH_RESET_EMAIL_SENT: '/authResetEmailSent',
  AUTH_SIGNUP_CONFIRM: '/authSignupConfirm',
  HOME: '/',
  IMAGE: '/image/:image_filter',
  TRASH: '/trash/:image_filter',
  IMAGE_TRASH_MORE: '/imageTrashMore',
  CAMERA: '/camera',
  IMAGE_TAG: '/imageTag',
  CAMERA_GROUP: '/cameraGroup',
  CAMERA_ADD: '/cameraAdd',
  CAMERA_EDIT: '/cameraEdit/:camera_id',
  CAMERA_EDIT_PIN: '/cameraEditPin/:pin_id',
  CAMERA_REMOTE: '/cameraRemote',
  CAMERA_ADD_PIN: '/cameraAddPin',
  SMART_FEEDER: '/smartFeeder',
  ACTIVITY: '/activity',
  BESTTIME: '/besttime',
  PROFILE: '/profile',
  DETECTION_THRESHOLD: '/detectionThreshold',
  IMAGE_UPLOAD: '/imageUpload',
  SUB_CAMERA: '/sub_camera',
  SUB_IMAGE: '/sub_image/:image_filter',
  SUB_ACTIVITY: '/sub_activity',
  WEATHER: '/weather',
  PREDICTION: '/prediction',
  MANAGE_SHARE: '/share/manage',
  SHARED_CAMERA: '/shared/camera',
  SHARE_IMAGE: '/share/image',

  ACCOUNT_MANAGEMENT: '/accountManagement',
  ACCOUNT_CAMERA: '/accountCamera',
  ACCOUNT_UPDATE_SUBSCRIPTION_STANDARD: '/accountUpdateSubscriptionStandard',
  ACCOUNT_UPDATE_SUBSCRIPTION_PLUS: '/accountUpdateSubscriptionPlus',
  ACCOUNT_PAYMENT_METHOD_CANCEL: '/accountPaymentMethodCancel',
  ACCOUNT_PAYMENT_METHOD_SUCCESS: '/accountPaymentMethodSuccess/:session_id',
  DATA_USAGE: '/dataUsage',
  MOVEMENT_MAP: '/movementMap',
};
